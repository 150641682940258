import { Button } from "@/components/ui/button";
import {
  Users,
  MapPin,
  Chrome,
  Calendar,
  ChevronRight,
  Globe,
  ChevronDown,
  Menu,
} from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logoWhite from "@/assets/img/logo.png";
import subheroImage from "@/assets/img/subhero-img.png";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useEffect, useState } from "react";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";

const LANGUAGE_KEY = "language_code";
const languages = ["🇬🇧 English", "🇪🇸 Español"];
const languagesCodes = {
  "🇬🇧 English": "en",
  "🇪🇸 Español": "es",
};

interface MobileLinkProps {
  href: string;
  onOpenChange?: (open: boolean) => void;
  children: React.ReactNode;
  className?: string;
}

function MobileLink({
  href,
  onOpenChange,
  className,
  children,
  ...props
}: MobileLinkProps) {
  return (
    <a
      href={href}
      onClick={() => {
        onOpenChange?.(false);
      }}
      className={className}
      {...props}
    >
      {children}
    </a>
  );
}

const getStorageLanguage = async () => {
  return localStorage.getItem(LANGUAGE_KEY);
};

const setStorageLanguage = async (code: string) => {
  localStorage.setItem(LANGUAGE_KEY, code);
};

export default function LandingPage() {
  const webUrl = "https://app.jumpballapp.com/auth/login";
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getStorageLanguage().then((code) => {
      setLanguage(code || "es");
      i18n.changeLanguage(code || "es");
    });
  }, []);

  const changeLanguage = (lang: "🇬🇧 English" | "🇪🇸 Español") => {
    const code = languagesCodes[lang];
    setLanguage(code);
    setStorageLanguage(code);
    i18n.changeLanguage(code);
  };

  const scrollIntoSection = (name: string, e: any) => {
    let section = document.getElementById(name);
    e.preventDefault();
    section && section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* <header className="px-4 lg:px-6 h-14 flex items-center bg-transparent fixed text-primary-foreground w-full text-shadow">
        <Link className="flex items-center justify-center" to="/">
          <img
            src={logoWhite}
            alt="Logo"
            style={{
              height: 30,
              WebkitFilter: "drop-shadow(1px 2px 2px #00000063)",
              filter: "drop-shadow(1px 2px 2px #00000063)",
            }}
          />
        </Link>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <a
            className="text-sm font-medium hover:underline underline-offset-4"
            href="#features"
            onClick={(e) => scrollIntoSection("features", e)}
          >
            {t("Features")}
          </a>
          <a
            className="text-sm font-medium hover:underline underline-offset-4"
            href="#how-it-works"
            onClick={(e) => scrollIntoSection("how-it-works", e)}
          >
            {t("How It Works")}
          </a>
          <a
            className="text-sm font-medium hover:underline underline-offset-4"
            href="#download"
            onClick={(e) => scrollIntoSection("download", e)}
          >
            {t("Try It Now!")}
          </a>
        </nav>
      </header> */}
      <header className="md:px-4 lg:px-6 h-14 flex items-center bg-transparent fixed text-primary-foreground w-full text-shadow">
        <div className="container flex h-14 items-center">
          <Sheet open={isOpen} onOpenChange={setIsOpen}>
            <SheetTrigger asChild>
              <Button
                variant="ghost"
                className="px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 md:hidden"
              >
                <Menu className="h-6 w-6" />
                <span className="sr-only">Toggle Menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="pr-0">
              <MobileLink
                href="/"
                className="flex items-center"
                onOpenChange={setIsOpen}
              >
                <span className="font-bold">
                  <img
                    src={logoWhite}
                    alt="Logo"
                    style={{
                      height: 30,
                      WebkitFilter: "drop-shadow(1px 2px 2px #00000063)",
                      filter: "drop-shadow(1px 2px 2px #00000063)",
                    }}
                  />
                </span>
              </MobileLink>
              <div className="my-4 h-[calc(100vh-8rem)] pb-10 pl-6">
                <div className="flex flex-col space-y-3">
                  <MobileLink
                    key="features"
                    href="#features"
                    onOpenChange={setIsOpen}
                  >
                    {t("Features")}
                  </MobileLink>
                  <MobileLink
                    key="how-it-works"
                    href="#how-it-works"
                    onOpenChange={setIsOpen}
                  >
                    {t("How It Works")}
                  </MobileLink>
                  <MobileLink
                    key="download"
                    href="#download"
                    onOpenChange={setIsOpen}
                  >
                    {t("Try It Now!")}
                  </MobileLink>
                </div>
              </div>
            </SheetContent>
          </Sheet>
          <Link className="flex items-center justify-center" to="/">
            <img
              src={logoWhite}
              alt="Logo"
              style={{
                height: 30,
                WebkitFilter: "drop-shadow(1px 2px 2px #00000063)",
                filter: "drop-shadow(1px 2px 2px #00000063)",
              }}
            />
          </Link>
          <div className="mr-4 hidden md:flex">
            <nav className="flex items-center space-x-6 text-sm font-medium">
              <a
                className="text-sm font-medium hover:underline underline-offset-4 ml-4"
                href="#features"
                onClick={(e) => scrollIntoSection("features", e)}
              >
                {t("Features")}
              </a>
              <a
                className="text-sm font-medium hover:underline underline-offset-4"
                href="#how-it-works"
                onClick={(e) => scrollIntoSection("how-it-works", e)}
              >
                {t("How It Works")}
              </a>
              <a
                className="text-sm font-medium hover:underline underline-offset-4"
                href="#download"
                onClick={(e) => scrollIntoSection("download", e)}
              >
                {t("Try It Now!")}
              </a>
            </nav>
          </div>
          <div className="flex flex-1 items-center justify-between space-x-2 md:justify-end max-w-20 ml-auto">
            <div className="flex-1 md:w-auto md:flex-none float-right">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    className="w-full justify-start text-left md:w-auto text-shadow"
                  >
                    {language === "es" ? "🇪🇸 Idioma" : "🇬🇧 Language"}
                    <ChevronDown className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="start"
                  sideOffset={24}
                  className="w-[150px]"
                >
                  {languages.map((language) => (
                    <DropdownMenuItem
                      key={language}
                      onClick={() =>
                        changeLanguage(language as "🇬🇧 English" | "🇪🇸 Español")
                      }
                    >
                      {language}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </header>
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 hero-bg lg:h-screen">
          <div className="container px-4 md:px-6">
            <div className="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]">
              <div className="flex flex-col justify-center space-y-4 text-center lg:text-left mt-20 xl:mt-40">
                <div className="space-y-2">
                  <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none text-primary-foreground text-shadow">
                    {t("Connect, Play, and Score with JumpBall App")}
                  </h1>
                  <p className="max-w-[600px] text-background md:text-xl text-shadow">
                    {t(
                      "Organize basketball games and meet new players in your area. Your next great game is just a tap away!"
                    )}
                  </p>
                </div>
                <div className="flex flex-col gap-2 min-[400px]:flex-row justify-center lg:justify-start">
                  <Button
                    className="text-primary-foreground hover:bg-primary-foreground hover:text-primary"
                    onClick={() => document.location.replace(webUrl)}
                  >
                    <Chrome className="mr-2 h-5 w-5" />
                    {t("Try Online")}
                    <ChevronRight className="h-5 w-5" />
                  </Button>
                  <a
                    href="#features"
                    onClick={(e) => scrollIntoSection("features", e)}
                  >
                    <Button
                      variant="outline"
                      className="text-primary-foreground border-primary-foreground hover:text-primary"
                    >
                      {t("Learn More")}
                    </Button>
                  </a>
                </div>
              </div>
              <div className="mx-auto flex items-center justify-center"></div>
            </div>
          </div>
        </section>
        <section id="features" className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <img src={subheroImage} className="w-full" alt="App screenshot" />
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12">
              {t("Key Features")}
            </h2>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              <div className="flex flex-col items-center text-center">
                <Users className="h-12 w-12 mb-4 text-orange-500" />
                <h3 className="text-xl font-bold mb-2">{t("Find Players")}</h3>
                <p className="text-gray-500">
                  {t("Connect with basketball enthusiasts in your area.")}
                </p>
              </div>
              <div className="flex flex-col items-center text-center">
                <MapPin className="h-12 w-12 mb-4 text-orange-500" />
                <h3 className="text-xl font-bold mb-2">{t("Locate Courts")}</h3>
                <p className="text-gray-500">
                  {t("Discover nearby basketball courts for your games.")}
                </p>
              </div>
              <div className="flex flex-col items-center text-center">
                <Calendar className="h-12 w-12 mb-4 text-orange-500" />
                <h3 className="text-xl font-bold mb-2">
                  {t("Schedule Games")}
                </h3>
                <p className="text-gray-500">
                  {t("Easily organize and join basketball games.")}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section
          id="how-it-works"
          className="w-full py-12 md:py-24 lg:py-32 bg-gray-100 hiw-bg"
        >
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-center mb-12 text-primary-foreground text-shadow">
              {t("How It Works")}
            </h2>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              <div className="flex flex-col items-center text-center">
                <div className="w-12 h-12 rounded-full bg-orange-500 text-white flex items-center justify-center text-2xl font-bold mb-4">
                  1
                </div>
                <h3 className="text-xl font-bold mb-2 text-primary-foreground text-shadow">
                  {t("Create Your Profile")}
                </h3>
                <p className="text-primary-foreground text-shadow">
                  {t(
                    "Sign up and set your preferences for playing basketball."
                  )}
                </p>
              </div>
              <div className="flex flex-col items-center text-center">
                <div className="w-12 h-12 rounded-full bg-orange-500 text-white flex items-center justify-center text-2xl font-bold mb-4">
                  2
                </div>
                <h3 className="text-xl font-bold mb-2 text-primary-foreground text-shadow">
                  {t("Find or Create a Game")}
                </h3>
                <p className="text-primary-foreground text-shadow">
                  {t("Browse available games or start your own match.")}
                </p>
              </div>
              <div className="flex flex-col items-center text-center">
                <div className="w-12 h-12 rounded-full bg-orange-500 text-white flex items-center justify-center text-2xl font-bold mb-4">
                  3
                </div>
                <h3 className="text-xl font-bold mb-2 text-primary-foreground text-shadow">
                  {t("Play and Connect")}
                </h3>
                <p className="text-primary-foreground text-shadow">
                  {t("Meet up, play basketball, and make new friends!")}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="download" className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">
                  {t("Try JumpBall Today")}
                </h2>
                <p className="mx-auto max-w-[600px] text-gray-500 md:text-xl dark:text-gray-400">
                  {t(
                    "Get ready to revolutionize your basketball experience. Download the app now!"
                  )}
                </p>
              </div>
              <div className="space-x-4">
                <Button
                  className="inline-flex items-center"
                  onClick={() => document.location.replace(webUrl)}
                >
                  <Chrome className="mr-2 h-5 w-5" />
                  {t("Try Online")}
                  <ChevronRight className="h-5 w-5" />
                </Button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500 dark:text-gray-400">
          © 2024 JumpBall. All rights reserved.
        </p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <Link className="text-xs hover:underline underline-offset-4" to="#">
            Terms of Service
          </Link>
          <Link className="text-xs hover:underline underline-offset-4" to="#">
            Privacy
          </Link>
        </nav>
      </footer>
    </div>
  );
}

// <Button
//   className="inline-flex items-center"
//   variant="outline"
//   disabled
// >
//   <Play className="mr-2 h-5 w-5" />
//   Google Play
// </Button>
